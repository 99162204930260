import * as React from "react";

import TestimonialCarousel from "../testimonial-carousel";

const testimonials = [
  {
    company: "PlanetTeach",
    name: "Dave Deane",
    role: "Founder",
    testimonial:
      "'Gravitywell has gone above and beyond to ensure PlanetTeach has every possible chance of success. The quality of work produced by the team has been exceptional and as we now enter the market, we have every belief that children and schools will love what has been created.'"
  }
  // {
  //   company: "Maya",
  //   name: "Dan Sheppard",
  //   role: "Founder",
  //   testimonial:
  //     "'Testimonial from Dan'"
  // },
  // {
  //   company: "1275",
  //   name: "Denis Houles",
  //   role: "Founder",
  //   testimonial:
  //     "'Testimonial from Denis'"
  // },
];

/**
 * Testimonials
 */
const Testimonials: React.FC = () => (
  <>
    <h2 className="off-screen">
      Testimonials
    </h2>

    <TestimonialCarousel
      content={testimonials}
      backgroundColor="altLightGrey"
    />
  </>
);

export default Testimonials;
