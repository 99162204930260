import React, { useRef } from "react";
import styled from "styled-components";

import { CtaButton } from "src/components/cta";
import Footer from "src/components/footer";
import GetInTouch from "src/components/get-in-touch";
import Header from "src/components/header";
import HelmetWrapper from "src/components/helmet-wrapper";
import { breadCrumbSchema } from "src/components/html-schema-snippets";
import Insights from "src/components/insights";
import Layout from "src/components/layout";
import WhyBristolStartups from "src/components/startups-landing-page/BristolStartups";
import CaseStudyPanel from "src/components/startups-landing-page/CaseStudyPanel";
import { PageTopStartups } from "src/components/startups-landing-page/PageTopStartups";
import Testimonials from "src/components/startups-landing-page/Testimonials";
import WhatWeOffer from "src/components/startups-landing-page/WhatWeOffer";
import WhyGravitywellStartups from "src/components/startups-landing-page/WhyGravitywell";

const StartupsPage = (): JSX.Element => {
  const contactRef = useRef<HTMLDivElement | null>(null);
  const handleCTAClick = () => scrollSmoothly(contactRef);
  const canonicalPath = "/startups";

  const breadcrumbsSchemaData = breadCrumbSchema([
    {
      name: "Startups",
      path: canonicalPath
    }
  ]);

  const schemaJson = [ breadcrumbsSchemaData ];

  return (
    <Layout className="layout">
      <HelmetWrapper
        title="Startups"
        description="We help startups blossom and achieve their goals"
        canonicalPath={canonicalPath}
      >
        <script type="application/ld+json">
          {JSON.stringify(schemaJson)}
        </script>
      </HelmetWrapper>

      <header role="banner">
        <Header
          initialLogo
          initialTheme="dark"
        />

        <PageTopStartups />
      </header>

      <main>

        <WhatWeOffer onCTAClick={handleCTAClick} />

        <CaseStudyPanel
          label="PlanetTeach"
          heading="Pioneering intuitive learning within the world of EdTech"
          linkCta="View Project"
          linkUrl="/projects/planetteach"
          backgroundImage="https://www.gravitywell.co.uk/static/be89e42e749cf8b82e1888790a4508e7/b2dea/School_children_with_iPads_01.jpg"
          desktopLayout="left"
          keyPoints={[
            "Started off as a hackathon",
            "Designed, developed and launched within weeks",
            "Excellent user feedback",
            "Platform endorsed by local eduction authority"
          ]}
        />

        <WhyGravitywellStartups />

        <CaseStudyPanel
          label="Found"
          heading="Reuniting owners with lost property using AI & Machine Learning"
          linkCta="View Project"
          linkUrl="/projects/found"
          backgroundImage="https://www.gravitywell.co.uk/static/33ed546a86b47becf7b67990a881e0e0/48b74/FoundApp_valuables_1460.jpg"
          desktopLayout="right"
          keyPoints={[
            "Close collaboration with the client throughout",
            "Extensive research undertaken prior to design",
            "Cutting edge look and feel & brand positioning",
            "Superb user reviews"
          ]}
        />

        <Testimonials />

        <WhyBristolStartups />

        <Insights
          title="Insights"
          gradient="orangePink"
          articles={articles}
        />
      </main>

      <Styled.FloatingCTA>
        <CtaButton
          type="primary"
          href="#get-in-touch"
          text="Work with us"
          className="floating-cta"
        />
      </Styled.FloatingCTA>

      <footer
        role="contentinfo"
        ref={contactRef}
        style={{
          position: "relative",
          zIndex: 3
        }}
      >
        <GetInTouch
          title="Let's get started"
          slogan="Got a great idea?"
        />

        <Footer />
      </footer>
    </Layout>
  );
};

const Styled = {
  FloatingCTA: styled.div`
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 3;
    margin: 2rem;
  `
};

const articles = [
  {
    title: "What do investors look for in your tech startup?",
    path: "/insights/what-do-investors-look-for-in-your-tech-startup/",
    created: "2021-10-11T00:00:00.000Z",
    author: "Hugo Walker",
    cover: {
      localFile: {
        childImageSharp: null,
        publicURL: "https://www.gravitywell.co.uk/static/f5d3b1a36ecca5ca4021935e5a4930b1/a793c/cover-investors.jpg"
      },
      filename: "file"
    }
  },
  {
    title: "Keeping app development costs down for startups",
    path: "/insights/keeping-app-development-costs-down-for-startups/",
    created: "2020-10-30T00:00:00.000Z",
    author: "Henry Osadzinski",
    cover: {
      localFile: {
        childImageSharp: null,
        publicURL: "https://www.gravitywell.co.uk/static/e69b1b6818388ac7b3cd42448294e7a2/d3746/dev-costs-cover.jpg"
      },
      filename: "file"
    }
  },
  {
    title: "5 steps to Startup success",
    path: "/insights/5-steps-to-startup-success/",
    created: "2021-04-28T00:00:00.000Z",
    author: "Hugo Walker",
    cover: {
      localFile: {
        childImageSharp: null,
        publicURL: "https://www.gravitywell.co.uk/static/3ed3bb68819e713e4fe5ccc8219e50f1/d3746/startup-sucess-cover.jpg"
      },
      filename: "file"
    }
  }
];

const scrollSmoothly = (ref: React.MutableRefObject<HTMLDivElement|null>) => {
  const y_coord = ref?.current?.offsetTop ?? window.innerHeight;
  const padding = document.documentElement.querySelector("[class*='navigation-']")?.clientHeight ?? window.innerHeight / 6;

  window.scrollTo({
    top: y_coord - padding,
    behavior: "smooth"
  });
};

export default StartupsPage;