import React from "react";

import VideoBackgroundImage from "src/assets/images/application-development/app-video-backround-fallback.jpg";
import { FullWidthColumnWrapper } from "src/components/confines-wrappers";
import HeroVideo from "src/components/hero-video";
import PageTop from "src/components/page-top";
import styled from "src/theme/styled";
import {
  colourHelper,
  fontLineHeightHelper,
  fontSizeHelper,
  fontWeightHelper
} from "src/theme/theme-helper";

import { CtaButton } from "../cta";
import Link from "../link/link";

export const PageTopStartups = (): JSX.Element => {
  return (
    <PageTopCustom id="hero">
      <PageTop
        style={{
          paddingTop: 0,
          paddingBottom: 0
        }}
        height="large"
      >
        <HeroVideo
          src="https://cdn.gravitywell.co.uk/videos/application-development/Landing-Page-Apps_04.m3u8"
          fallbackImage={VideoBackgroundImage}
        />

        <FullWidthColumnWrapper className="top-content">
          <p className="heading">
            We help startups blossom and achieve their goals
          </p>
        </FullWidthColumnWrapper>

        <div
          className="section-cta-container"
          style={{ position: "relative" }}
        >
          <p className="book">
            <span className="off-screen">
              Click here and
              {" "}
            </span>

            <CtaButton
              type="primary"
              href="#get-in-touch"
              text="Work with us"
            />
          </p>
        </div>
      </PageTop>
    </PageTopCustom>
  );
};

const PageTopCustom = styled.div`
  .top-content-container {
    .top-content-wrapper {
      position: absolute;
      left: 0;
      top: 0;

      .top-content {
        z-index: 3;
        text-align: center;

        .heading {
          transition: 0.2s ease-in-out;
          display: block;
          margin: 0 auto;
          width: 26rem;
          color: ${({ theme }) => colourHelper({
    theme,
    variant: "white"
  })};
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_2"
    })};
          font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "heavy"
    })};
          line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};
        }
      }
    }
  }

  @media (min-width: 375px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 32rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "title"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 480px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 39rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_1"
    })};
          }
        }
        }
      }
    }
  }

  @media (min-width: 568px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 53rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_3"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 60rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_4"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 960px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 66rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_5"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 1280px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 74rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_6"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 1440px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 80rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_7"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 1920px) {
    .top-content-container {
      .top-content-wrapper {
        .top-content {
          .heading {
            width: 96rem;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_9"
    })};
          }
        }
      }
    }
  }

  .section-cta-container {
    padding-top: 6.8rem;
    width: 100%;

    p {
      padding-top: 0;
      text-align: center;
      font-size: ${({ theme }) => fontSizeHelper({
    theme,
    variant: "body_1"
  })};
      font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "bold"
    })};
      line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};

      a {
        color: ${({ theme }) => colourHelper({
    theme,
    variant: "white"
  })};
        border-bottom-color: rgba(255, 255, 255, 0.4);

        &:hover {
          border-bottom-color: rgba(255, 255, 255, 1);
        }
      }
    }
  }

  @media (min-width: 480px) {
    .section-cta-container {
      padding-top: 8.8rem;
    }
  }

  @media (min-width: 960px) {
    .articles-grid-container {
      padding-top: 8%;
    }

    .section-cta-container {
      p {
        font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};
      }
    }
  }

  @media (min-width: 1440px) {
    .section-cta-container {
      padding-top: 10.2rem;
    }
  }
`;
