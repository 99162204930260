import React from "react";

import styled, { css } from "src/theme/styled";
import {
  colourHelper,
  fontSizeHelper,
  fontWeightHelper,
  spacingHelper
} from "src/theme/theme-helper";

import { MediumColumnWidthWrapper } from "../confines-wrappers";
import Link from "../link";
import Panel from "../panel-wrapper";

const our_services = [
  "Conception & Ideation",
  "User Testing",
  "Market Research",
  "Service Design",
  "Investment Support",
  "Technical Consultancy",
  "Brand Strategy",
  "Development & Software Testing",
  "Prototyping"
];

interface WhatWeOfferProps {
	onCTAClick: () => void;
}

const WhatWeOffer: React.FC<WhatWeOfferProps> = props => {
  const { onCTAClick } = props;

  return (
    <WhatWeOfferContainer>
      <Panel backgroundColor="black">
        <MediumColumnWidthWrapper className="content-container">
          <h4 role="heading">
            What we offer
          </h4>

          <div role="list">
            {our_services.map(str => (
              <p
                role="listitem"
                key={str}
              >
                {str}
              </p>
            ))}
          </div>

          <div className="section-cta-container">
            <p className="book">
              <span className="off-screen">
                Click here and
                {" "}
              </span>

              <Link
                type="styled"
                color="brandColor"
                href="##"
                onClick={onCTAClick}
              >
                Let's talk
              </Link>
            </p>
          </div>
        </MediumColumnWidthWrapper>
      </Panel>
    </WhatWeOfferContainer>
  );
};

const WhatWeOfferContainer = styled.div`
${({ theme }) => css`
    color: ${colourHelper({
    theme,
    variant: "white"
  })}

.content-container {
    --padding-x: ${spacingHelper({
    theme,
    variant: "l"
  })};
    padding-left: var(--padding-x);
    padding-right: var(--padding-x);
  }

  [role="heading"] {
    font-size: ${fontSizeHelper({
    theme,
    variant: "subhead_1"
  })};
      font-weight: ${fontWeightHelper({
    theme,
    variant: "heavy"
  })};
  text-align: center;
  margin-bottom: ${spacingHelper({
    theme,
    variant: "s"
  })};
  }

  [role="list"] {
    padding: ${spacingHelper({
    theme,
    variant: "l"
  })};
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  [role="listitem"] {
    flex-basis: 100%;
    display: list-item;
    padding: 0;
    margin-bottom: 1em;
    font-size: ${fontSizeHelper({
    theme,
    variant: "body_2"
  })};
  }

  .section-cta-container {
    padding-top: 0
    width: 100%;

    p {
      padding-top: 0;
      text-align: center;
      font-size: ${({ theme }) => fontSizeHelper({
    theme,
    variant: "body_1"
  })};
    }
    
    .book {
      padding-top: 2.6rem;
      font-size: ${fontSizeHelper({
    theme,
    variant: "body_1"
  })};
      font-weight: ${fontWeightHelper({
    theme,
    variant: "bold"
  })};
    }
  }
  

  @media (min-width: 375px) {
    [role="heading"] {
      font-size: ${fontSizeHelper({
    theme,
    variant: "subhead_2"
  })};
    }
  }
  
  @media (min-width: 480px) {
    [role="heading"] {
      font-size: ${fontSizeHelper({
    theme,
    variant: "subhead_3"
  })};
    }

    [role="list"] {
      padding: ${spacingHelper({
    theme,
    variant: "xl"
  })};
    }

    .section-cta-container {
      padding-top: ${spacingHelper({
    theme,
    variant: "xl"
  })};
    }
  }

  @media (min-width: 568px) {
    [role="heading"] {
      font-size: ${fontSizeHelper({
    theme,
    variant: "title"
  })};
    }
  }

  @media (min-width: 600px) {
    .content-container {
      --padding-x: ${spacingHelper({
    theme,
    variant: "xl"
  })};
    }
    
    [role="heading"] {
      font-size: ${fontSizeHelper({
    theme,
    variant: "display_1"
  })};
    }

    [role="list"] {
      padding: ${spacingHelper({
    theme,
    variant: "xxl"
  })};
    }
    
    [role="listitem"] {
      
}
  }
  
  @media (min-width: 768px) {
    .content-container {
      --padding-x: ${spacingHelper({
    theme,
    variant: "xxl"
  })};
      padding-top: var(--padding-x)
    }

    [role="heading"] {
      text-align: unset;
      margin-bottom: unset;
    }
    
    [role="listitem"] {
      flex-basis: 50%;
      white-space: nowrap;
    }

    .book {
      font-size: ${fontSizeHelper({
    theme,
    variant: "body_2"
  })};
    }
  }

  @media (min-width: 960px) {
    [role="listitem"] {
      font-size: ${fontSizeHelper({
    theme,
    variant: "body_2"
  })
}
  }

  @media (min-width: 1024px) {

  }

  @media (min-width: 1440px) {
    .section-cta-container {
      padding-top: calc(${spacingHelper({
    theme,
    variant: "xl"
  })} * 2);
    }
  }
  
`} /* end theme */
`;

export default WhatWeOffer;