import React from "react";

import styled, { css } from "src/theme/styled";
import {
  fontLineHeightHelper,
  fontSizeHelper,
  fontWeightHelper
} from "src/theme/theme-helper";

interface AuthorMugshotProps {
  imageUrl: string;
  name: string;
  jobTitle: string;
  link: string;
  colorScheme: "dark" | "light";
}

export const AuthorMugshot: React.FC<AuthorMugshotProps> = ({
  name, imageUrl, jobTitle, link, colorScheme
}) => {
  return (
    <AuthorMugshotContainer
      colorScheme={colorScheme}
      href={link}
    >
      <img
        className="image"
        src={imageUrl}
        alt={`Image ${name}, ${jobTitle}`}
      />

      <div>

        <span className="author-job-title">
          {jobTitle}
        </span>

        <p className="author-name">
          {name}
        </p>
      </div>

    </AuthorMugshotContainer>
  );
};

const AuthorMugshotContainer = styled.a<{colorScheme: "dark" | "light"}>`
${({ theme, colorScheme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center; 

    .image{
      width: 100px;
      height: 100px;
      margin-right: 2rem;
    }
    .author-job-title {
      text-transform: uppercase;
      color: ${colorScheme === "light" ? theme.color.white : theme.color.darkGrey}
      font-size: ${theme.font.size.small_1};
      line-height: ${theme.font.lineHeight.normalized};
      letter-spacing: ${theme.font.spacing.medium};
    }
    .author-name {
      padding-top: 0.4rem;
      font-size: ${theme.font.size.subhead_1};
      font-weight: ${theme.font.weight.heavy};     
      color: ${colorScheme === "light" ? theme.color.white : theme.color.darkGrey}
    }
  `}
`;
