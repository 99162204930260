import React from "react";
import ReactPlayer from "react-player";

import styled, { css } from "src/theme/styled";
import { AuthorMugshot } from "src/components/author-mugshot/AuthorMugshot";
import { MediumColumnWidthWrapper } from "src/components/confines-wrappers";

const WhyBristolStartups: React.FC = () => {
  return (
    <StyledWhyBristolSection
      id="why-bristol-section"
      className="why-bristol-section content-container"
    >
      <MediumColumnWidthWrapper className="why-bristol-section-wrapper">
        <div className="video-panel">
          <div className="video">
            <ReactPlayer
              config={{ youtube: { playerVars: { modestbranding: 1 } } }}
              className="react-player"
              width="100%"
              height="100%"
              volume={1}
              url="https://www.youtube.com/watch?v=9MevIstvAI0"
            />
          </div>
        </div>

        <div className="copy">
          <blockquote>
            Bristol is awash with hubs and has a fantastic collective, collaborative attitude...
          </blockquote>

          <AuthorMugshot
            colorScheme="light"
            name="Hugo Walker"
            jobTitle="Marketing & Digital Content"
            imageUrl="https://www.gravitywell.co.uk/static/a411fe6d0ba9efe044b576cca0b45191/94a79/Hugo-Walker_0.png"
            link="/about-us/hugo-walker"
          />

        </div>

      </MediumColumnWidthWrapper>
    </StyledWhyBristolSection>
  );
};

const StyledWhyBristolSection = styled.div`
  ${({ theme }) => css`
    background: ${theme.color.black};
    
    .why-bristol-section-wrapper {
      display: flex;
      flex-direction: column;

      @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      .copy {
        z-index: 2;
        padding-top: 4.2rem;

        @media (min-width: 768px) {
          padding-top: 0;
          width: 40%;
        }

        blockquote {
          &:before {
            content: '“';
            position: absolute;
            left: -10px;
          }
          &:after {
            content: '”';
            
          }
          
          margin: 4rem 2rem;
          @media (min-width: 768px) {
            margin: 0;
            margin-bottom: 4rem;
            max-width: 35rem;
          }
          
          position: relative;
          color: white;
          font-size: ${theme.font.size.subhead_1};
          font-weight: ${theme.font.weight.heavy};
          @media (min-width: 768px) {
            font-size: ${theme.font.size.subhead_2};
          }
        }
      }

      .video-panel {
        @media (min-width: 768px) {
          width: 45%;
          /* max-height: 30rem; */
        }
        transition: all 0.2s ease-in-out;
  
          @media (min-width: 768px) {
            margin-right: 5%;
          }
  
          @media (min-width: 1024px) {
            margin-right: 6%;
          }
  
          @media (min-width: 1280px) {
            margin-right: 8%;
          }
  
          @media (min-width: 1380px) {
            margin-right: 10%;
          }
          .video {
        width: auto; // Reset width
        height: auto; // Reset height
        padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
        position: relative;
       
        .react-player {
          position: absolute;
          top: 0;
          left: 0;          
        }
      }
      }
      
    }
  `};
`;

export default WhyBristolStartups;